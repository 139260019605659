@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga" 1, "kern" 1;
  }

  body {
    @apply bg-white dark:bg-dark-900 text-gray-900 dark:text-gray-100 transition-colors duration-150;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-display font-medium leading-tight text-gray-900 dark:text-white;
    letter-spacing: -0.02em;
  }
  
  h1 {
    @apply text-4xl md:text-5xl lg:text-6xl mb-6;
    line-height: 1.1;
  }
  
  h2 {
    @apply text-3xl md:text-4xl lg:text-5xl mb-5;
    line-height: 1.2;
  }
  
  h3 {
    @apply text-2xl md:text-3xl lg:text-4xl mb-4;
    line-height: 1.3;
  }
  
  h4 {
    @apply text-xl md:text-2xl lg:text-3xl mb-4;
    line-height: 1.4;
  }
  
  p {
    @apply font-sans text-base md:text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6;
    font-weight: 400;
  }

  .lead {
    @apply text-xl md:text-2xl font-light text-gray-600 dark:text-gray-300 leading-relaxed mb-8;
    letter-spacing: -0.01em;
  }
  
  .subtitle {
    @apply font-display text-lg md:text-xl text-gray-500 dark:text-gray-400 italic mb-4;
    font-weight: 400;
  }
  
  blockquote {
    @apply font-display text-xl md:text-2xl text-gray-700 dark:text-gray-300 italic pl-6 border-l-4 border-gray-300 dark:border-gray-600 my-8;
    line-height: 1.5;
  }
  
  code {
    @apply font-mono text-sm bg-gray-50 dark:bg-dark-800 px-1.5 py-0.5 rounded-md border border-gray-200 dark:border-dark-600;
  }

  strong {
    @apply font-semibold text-gray-900 dark:text-white;
  }

  a {
    @apply text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-200;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }

  ul, ol {
    @apply mb-6 pl-6;
  }

  li {
    @apply mb-2 text-gray-600 dark:text-gray-300;
  }

  .small-caps {
    @apply font-sans uppercase tracking-wider text-sm font-semibold text-gray-900 dark:text-gray-100;
  }
}

/* Dark mode transitions */
.dark-mode-transition {
  @apply transition-colors duration-200;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-50 dark:bg-dark-800;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-dark-600 rounded-full transition-colors hover:bg-gray-400 dark:hover:bg-dark-500;
}
